import { Goerli } from '@usedapp/core'

//export const ROUTER_ADDRESS = "0x3D15b6e56413e959Fb5255B1487E4b9FB7258757"; 
export const ROUTER_ADDRESS = "0xa209E39ffF78A84C80673C7200d1657D4280652F";

export const DAPP_CONFIG = {
  readOnlyChainId: Goerli.chainId,
  readOnlyUrls: {
    [Goerli.chainId]: 'https://eth-goerli.g.alchemy.com/v2/6RyJySUvgRHozlqhJX3lPBv0OlzRpguG',
   },
 };